<template lang="html">
  <router-view :daySelectedSet="daySelectedSet" :searchChangedSet="searchChangedSet"  @setDayChanged="dayChanged" @setSearchChanged="searchChanged"   :breweries="breweries" :savedList="savedList"></router-view>
</template>

<script>
export default {
  props:['breweries','savedList', 'daySelectedSet','searchChangedSet'],
  methods:{
    dayChanged(val){
      this.$emit('setDayChangedHome', val);
    },
    searchChanged(val){
      this.$emit('setSearchChangedHome', val);
    }
  }
}
</script>

<style lang="css">
</style>
