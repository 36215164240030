<template lang="html">
  <div>
      <div class="c-view-head">
        <div class="c-view-head-title">{{brewer.name}} ({{  $filters.countryName(brewer.country)  }})</div>
      </div>
      <div class="c-view-body c-view-parent" v-if="brewer">

        <div v-for="beer in brewer.beer" class="c-list-item">
          <app-CheckButton :item="beer" :parent="brewer"></app-CheckButton>
          <router-link :to="'/beer/'+brewer.id+'/'+beer.id"  class="c-list-item-link">
              <strong class="c-list-item-title">{{beer.name}}</strong>
              <span class="c-list-item-details">{{beer.abv}}% {{beer.style}}</span>
          </router-link>
          <app-FavButton :key="beer.id" :item="beer" :parent="brewer"></app-FavButton>
        </div>

    </div>
  </div>
</template>

<script>
import favButton from '../shared/favButton.vue';
import checkButton from '../shared/checkButton.vue';

export default {
  props:['breweries'],
  computed:{
    brewer(){
      const breweryId = this.$route.params.idBrewery;
      const items = this.breweries.filter(function(item) {
        return item.id == breweryId;
      });
      const item = items[0];
      return item;
    },
  },
  components:{
    appFavButton: favButton,
    appCheckButton: checkButton
  },
  mounted: function(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
</script>

<style lang="css">
</style>
