import Home from './components/home.vue';
import IndexList from './components/shared/index.vue';
import BreweriesList from './components/breweries/breweriesList.vue';
import Brewery from './components/breweries/brewery.vue';
import Beer from './components/beers/beerView.vue';
import Information from './components/information.vue';
import MyList from './components/user/myList.vue';

export const routes = [{
    path: '',
    components: {
      default: IndexList,
      
    },
      children: [{
          path: '',
          component: Home
        },
        {
          path: 'beer/:idBrewery/:idBeer',
          component: Beer,
          props: true,
      }]
  },
  {
    path: '/breweries/',
    components: {
      default: IndexList,
    },
    children: [{
        path: '',
        component: BreweriesList,
        props: true,
      },
      {
        path: ':idBrewery',
        component: Brewery,
        props: true,
      },
      {
        path: ':idBrewery/:idBeer',
        component: Beer,
        props: true,
      }
    ]
  },
  {
    path: '/info',
    components: {
      default: Information,
    }
  },
  {
    path: '/my-list',
    components: {
      default: MyList,
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
]
