<template lang="html">
  <div class="c-navbar">
    <router-link to="/" exact active-class="c-nav-link--active"  class="c-brand">
      <img :src="`${publicPath}logo.svg`" alt="" >
    </router-link>

    <ul class="c-nav">
			<li class="c-nav-item"><router-link to="/" exact active-class="c-nav-link--active"  class="c-nav-link">
        <svg viewBox="0 0 33 32" class="c-nav-link__icon icon icon-beer">
          <use xlink:href="#icon-beer"></use>
        </svg>
        <span class="c-nav-link__title">Beers</span>
      </router-link></li>
      <li class="c-nav-item"><router-link to="/breweries"  active-class="c-nav-link--active"  class="c-nav-link"> <svg viewBox="0 0 25 25" class="c-nav-link__icon icon icon-brewery">
          <use xlink:href="#icon-brewery"></use>
        </svg><span class="c-nav-link__title">Breweries</span></router-link></li>
      <li class="c-nav-item"><router-link to="/info"  active-class="c-nav-link--active"  class="c-nav-link"> <svg viewBox="0 0 23 23" class="c-nav-link__icon icon icon-info">
          <use xlink:href="#icon-info"></use>
        </svg><span class="c-nav-link__title">Info</span></router-link></li>
      <li class="c-nav-item"><router-link to="/my-list"   active-class="c-nav-link--active" class="c-nav-link"> <svg viewBox="0 0 33 32" class="c-nav-link__icon icon icon-list">
          <use xlink:href="#icon-list"></use>
        </svg><span class="c-nav-link__title">My List</span></router-link></li>
    </ul>

  </div>

</template>

<script>
export default {
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}
</script>

<style lang="css">
</style>
