<template lang="html">
  <div class="">
    <div class="c-view-head">
      <div class="c-view-head-title c-view-head-title--controls">

        <div class="tabs">
          <label class="tabs__item">
            <input type="radio" value="friday" v-model="daySelected" @change="daySelect" class="visuallyhidden tabs__input " />
            <span class="tabs__title">Friday</span>
          </label>
          <label class="tabs__item">
            <input type="radio" value="saturday" v-model="daySelected" @change="daySelect" class="visuallyhidden tabs__input " />
            <span class="tabs__title">Saturday</span>
          </label>
        </div>

        <!--div class="f-select">
          <select v-model="daySelected" @change="daySelect">
            <option value="friday">Friday</option>
            <option value="saturday">Saturday</option>
          </select>
        <svg viewBox="0 0 8 8" class="icon icon-dropdown hidden">
          <use xlink:href="#icon-dropdown"></use>
        </svg>
        </div-->
      </div>
    </div>

    <div class="c-view-body c-list-view">
      <div class="c-view-search">
        <input type="text" class="input" :placeholder="filterPlaceholder" v-model="styleFilter" @input="updateValue">
        <button v-if="styleFilter" class="c-view-search__reset" type="reset" @click="styleFilter = ''"><span class="">Clear</span></button>

      </div>
    
        <div v-for="(brewer, index) in breweries" class="c-list-parent" :key="brewer.id" >
        
          	<h2 class="c-list-parent-title" ><router-link :to="'/breweries/'+brewer.id" class="c-fitLine"><span class="c-fitLine__trun" :title="brewer.name">{{brewer.name}}</span> <span class="country c-fitLine__fit">{{brewer.country}}</span></router-link></h2>

            <template v-for="(beer, index) in brewer.beer"  :key="beer.id">
              <div v-if="beer.stage.includes(daySelected) && (beer.style.match(stylefiltered) || beer.name.match(stylefiltered) || brewer.name.match(stylefiltered))" class="c-list-item">
                <router-link :to="'/beer/'+brewer.id+'/'+beer.id"  class="c-list-item-link">
                <strong class="c-list-item-title">{{beer.name}}</strong>
                <span class="c-list-item-details">{{beer.abv}}% {{beer.style}}</span>
              </router-link>
              <app-FavButton :key="beer.id" :item="beer" :parent="brewer"></app-FavButton>
              </div>
              
            </template>
            </div>
   


    </div>

  </div>
</template>

<script>
import favButton from './shared/favButton.vue';
export default {
  props:['breweries','savedList', 'daySelectedSet','searchInputSet'],
  data(){
    return{
      daySelected:"",
      //styleFilter:localStorage.getItem('inputValue') || '',
      styleFilter:'',
      searchSaved: "",
      daySaved:this.daySelectedSet,
      filterPlaceholder: window.lang.en.filterPlaceholder
    }
  },
  watch:{
    styleFilter: function(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    daySelected: function(){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
  computed:{
    stylefiltered(){
      var search = new RegExp( this.styleFilter, 'i' );
      return search;
    }
  },
  created: function(){
      // check is user has selected day, if not choose from calendar
      if(this.daySaved){
          this.daySelected=this.daySaved;
      }else{
        var today = new Date().getDay();
        if(today==6){
          this.daySelected = "saturday";
        }else{
          this.daySelected = "friday";
        }
      }
  },
  updated: function(){
  //  console.log("home updated");

  },
  components:{
    appFavButton: favButton
  },
  methods: {
    daySelect(){
        this.$emit('setDayChanged', this.daySelected);
    },
    updateValue(event) {
      //this.styleFilter = event.target.value;
      //localStorage.setItem('inputValue', this.styleFilter);
    },
    searchChange(){
        this.$emit('setSearchChanged', this.styleFilter);
        console.log("aa");
    }

  }

}
</script>

<style lang="css">

</style>
