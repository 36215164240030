import * as Vue from 'vue'
import App from './App.vue'


import { createRouter, createWebHistory   } from 'vue-router'
import {routes} from './routes';

import axios from 'axios';
axios.defaults.baseURL = window.settings.dataPath;

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  //base: window.settings.routerBase,
  //mode: window.settings.routerMode
});





const app = Vue.createApp(App)
app.use(router)
app.config.warnHandler = () => null;
app.mount('#app')


app.config.globalProperties.$filters = {
  countryName(value) {
    if(value.length<=3){
      return value.toUpperCase();
    }else{
      return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
  },

  countryNameShort(value) {
    if(value.length<=3){
      return value.toUpperCase();
    }else{
      var newVal = value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
      return newVal.substring(0, 3);
    }
  },

  longText(value) {
     if(value.length>=30){
      return " hasLongText ";
    }
  }


}
