<template lang="html">
  <button @click="toggleFav(item, parent)" class="b-toggle b-item-fav"  :class="{ 'b-selected': favState}">
    <svg viewBox="0 0 32 32" class="icon icon-star">
      <use xlink:href="#icon-star"></use>
    </svg>
    <span class="b-toggle-label" v-if="favState">{{favStateLabelYes}}</span>
    <span class="b-toggle-label" v-else>{{favStateLabelNo}}</span>
  </button>
</template>

<script>
export default {
    props:['item', 'parent'],
    data(){
        return{
          favState: this.item.isFav,
          favStateLabelYes: window.lang.en.favLabel.yes,
          favStateLabelNo: window.lang.en.favLabel.no,
        }
    },
    methods: {
      toggleFav(item, parent){
        this.favState = !this.favState;
        this.item.isFav = !this.item.isFav;
        const state = this.favState;
        const breweryId = this.parent.id;
        const beerId = this.item.id;
        const toggleItem = {
          id: breweryId,
          beer: [{
            id: beerId,
            isFav: state
          }]
        };
        var stateName="";
        if(state){
          stateName="on";
        }else{
          stateName="off";
        }
        // Check if have local list
        if (localStorage.getItem("savedList") === null) {
          var storedList = new Array() ;
        }else{
          var storedList = JSON.parse(localStorage.getItem('savedList'));
        }

        // Find Brewery index
        const findStoredBrewery = storedList.findIndex(function (item) {
          return item.id == breweryId;
        });


        if(findStoredBrewery >=0){
          const findStoredBeer = storedList[findStoredBrewery].beer.findIndex(function (item) {
            return item.id == beerId;
          });
          if(findStoredBeer>=0){
              storedList[findStoredBrewery].beer[findStoredBeer].isFav = state;
          }else{
            const newBeer = {
              id: beerId,
              isFav: state
            };
            storedList[findStoredBrewery].beer.push(newBeer);
          }
        }else{
          storedList.push(toggleItem);
        }

        localStorage.setItem('savedList', JSON.stringify(storedList));
        window.ga('send', 'event', {
          eventCategory: 'List',
          eventAction: stateName,
          eventLabel: this.parent.name +": "+ this.item.name
        });
      }
    }
}
</script>
