<template lang="html">
  <div>
    <div class="c-view-head">
      <div class="c-view-head-title">
      Breweries
      </div>
    </div>

		<div class="c-view-body c-view-parent">
      <div class="c-category-list">
        <div v-for="brewer in breweries" class="c-category-list-item">
          <router-link :to="'/breweries/'+brewer.id" class="c-category-list-item-link">

            {{brewer.name}} <span class="c-category-list-item-country">{{brewer.country}}</span>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['breweries'],
  data(){
    return{
      assetsPath: window.settings.assetsPath,
    }
  }
}
</script>

<style lang="css">
</style>
