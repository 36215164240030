<template lang="html">
  <div class="">

    <div class="c-view-head">

      <div class="c-view-head-title c-view-head-title--controls">

        <div class="tabs">
          <label class="tabs__item">
            <input type="radio" value="friday" v-model="daySelected"  class="visuallyhidden tabs__input " />
            <span class="tabs__title">My Friday <span class="badge">{{ totalFriday }}</span></span>
          </label>
          <label class="tabs__item">
            <input type="radio" value="saturday" v-model="daySelected" class="visuallyhidden tabs__input " />
            <span class="tabs__title">My Saturday <span class="badge">{{ totalSaturday }}</span></span>
          </label>
        </div>
      </div>

  </div>
    <div class="c-view-body c-list-view">

      <div v-for="brewer in breweries">
          <div v-for="beer in brewer.beer"  class="c-list-item">
            <template v-if="beer.isFav==true && beer.stage.includes(daySelected)">
               <app-CheckButton :key="beer.id" :item="beer" :parent="brewer"></app-CheckButton>
              <router-link :to="'/beer/'+brewer.id+'/'+beer.id"  class="c-list-item-link">
              <span class="c-list-item-country">{{brewer.name}}</span>
              <strong class="c-list-item-title">{{beer.name}}</strong>
              <span class="c-list-item-details">{{beer.abv}}% {{beer.style}}</span>
            </router-link>
            </template>
             
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import favButton from '../shared/favButton.vue';
import checkButton from '../shared/checkButton.vue';

export default {
  data(){
    return{
      daySelected:"friday"
    }
  },
  computed:{
    totalFriday(){
        var total = 0;
        this.breweries.forEach(function(brewery, index) {
            brewery.beer.forEach(function(beer, index){
                if(beer.isFav && beer.stage.includes("friday"))
                  total ++;
            }
          );
        })
        if(total>0){
          return "" + total + "";
        }else{
          "";
        }

    },
    totalSaturday(){
        var total = 0;
        this.breweries.forEach(function(brewery, index) {
            brewery.beer.forEach(function(beer, index){
                if(beer.isFav && beer.stage.includes("saturday"))
                  total ++;
            }
          );
        })
        if(total>0){
          return "" + total + "";
        }else{
          "";
        }
    }
  },
  created: function(){
      var today = new Date().getDay();
      if(today==6){
        this.daySelected = "saturday";
      }else{
        this.daySelected = "friday";
      }
  },

  mounted: function(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  components:{
    appFavButton: favButton,
    appCheckButton: checkButton
  },
  props:['breweries']
}
</script>

<style lang="css">
</style>
