<template lang="html">
  <div class="c-note">
    <textarea v-model="note" class="input c-note-input " placeholder="my notes"></textarea>
    <button @click="saveNotes" class="b-note-submit">Save my note</button>
  </div>
</template>

<script>
export default {
    props:['item', 'parent'],
    data(){
        return{
          note: this.item.note
        }
    },
    methods: {
      saveNotes(item, parent){
        this.item.note = this.note;
        const note = this.note;
        const breweryId = this.parent.id;
        const beerId = this.item.id;
        const noteItem = {
          id: breweryId,
          beer: [{
            id: beerId,
            note: note
          }]
        };

        // Check if have local list
        if (localStorage.getItem("savedList") === null) {
          var storedList = new Array() ;
        }else{
          var storedList = JSON.parse(localStorage.getItem('savedList'));
        }

        // Find Brewery index
        const findStoredBrewery = storedList.findIndex(function (item) {
          return item.id == breweryId;
        });


        if(findStoredBrewery >=0){
          const findStoredBeer = storedList[findStoredBrewery].beer.findIndex(function (item) {
            return item.id == beerId;
          });
          if(findStoredBeer>=0){
              storedList[findStoredBrewery].beer[findStoredBeer].note = note;
          }else{
            const newBeer = {
              id: beerId,
              note: note
            };
            storedList[findStoredBrewery].beer.push(newBeer);
          }
        }else{
          storedList.push(noteItem);
        }

        localStorage.setItem('savedList', JSON.stringify(storedList));
      }
    }
}
</script>
