<template lang="html">
  <div>
    <div class="c-view-head">
      <div class="c-view-head-title">Information</div>
    </div>
  <div class="c-view-body">
    <div v-html="information"></div>

  </div>
</div>
</template>

<script>
  
export default {
  props:['information']
}
</script>

<style lang="css">
</style>
