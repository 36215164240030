<template lang="html">
  <div class="" v-if="brewer">
    <div class="c-view-head">

      <div class="c-view-head-button">
				<a @click="$router.go(-1)">
          <svg viewBox="0 0 32 32" class="icon icon-back">
							  <use xlink:href="#icon-back"></use>
					</svg>
        </a>
		  </div>
        <div class="c-view-head-title " :class="beer.name | $filters.longText">
          <span>{{beer.name}}</span>
        </div>
    </div>
		<div class="c-view-body c-view-item">
      <dl>
  		<dt>Brewery	</dt>
  		<dd>
        <router-link :to="'/breweries/'+brewer.id">{{brewer.name}} ({{ $filters.countryName(brewer.country)  }})</router-link>
      </dd></dl>

  		<dl>
  		    <dt>Beer</dt>
  		    <dd>{{beer.name}}</dd>
  		</dl>
      <dl>
  		    <dt>Day</dt>
  		    <dd><span v-for="stage in beer.stage" class="t-sentence">
              {{stage}}
          </span></dd>
  		</dl>

      <dl>
  		<dt>ABV, Style</dt>
  		<dd>{{beer.abv}}% {{beer.style}}</dd>
    </dl>

   

    <div class="c-buttons-group">
    <app-FavButton  :item="beer" :parent="brewer"></app-FavButton>
    <app-CheckButton :key="beer.id" :item="beer" :parent="brewer"></app-CheckButton>
      <template v-if="beer.untappd">
          <a :href="'untappd://beer/' + beer.untappd" target="_blank" class="b-toggle b-untappd visibleMobile"> <svg viewBox="0 0 32 32" class="icon icon-untappd">
      <use xlink:href="#icon-untappd"></use>
    </svg><span class="b-toggle-label">Untappd</span></a>
          <a :href="'https://untappd.com/beer/' + beer.untappd" target="_blank" class="b-toggle b-untappd hiddenMobile"> <svg viewBox="0 0 32 32" class="icon icon-untappd">
      <use xlink:href="#icon-untappd"></use>
    </svg><span class="b-toggle-label">Untappd</span></a>
      </template>
    </div>
    <app-Notes :item="beer" :parent="brewer"></app-Notes>

    </div>
  </div>
</template>

<script>
import favButton from '../shared/favButton.vue';
import checkButton from '../shared/checkButton.vue';
import notes from '../shared/notes.vue';
import axios from 'axios';

export default {

  props: ['breweries', 'savedList'],
  data() {
    return {
      //  isFav:false
    }
  },
  computed: {
    backLink(){
      return this.$router.go(-1);
    },
    brewer() {
      const breweryId = this.$route.params.idBrewery;
      const items = this.breweries.filter(function(item) {
        return item.id == breweryId;
      });
      const item = items[0];
      return item;

    },
    beer() {
      const beerId = this.$route.params.idBeer;
      const items = this.brewer.beer.filter(function(item) {
        return item.id == beerId;
      });
      var item = items[0];
      return item;
    }
  },
  components: {
    appFavButton: favButton,
    appCheckButton: checkButton,
    appNotes: notes
  },
  
  mounted: function(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }


}
</script>

<style lang="css" scoped>

</style>
