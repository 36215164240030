<template lang="html">
  <button @click="toggleFav(item, parent)" class="b-toggle b-item-check"  :class="{ 'b-selected': checkState}">
    <svg viewBox="0 0 32 32" class="icon icon-check">
      <use xlink:href="#icon-check"></use>
    </svg>
    <span class="b-toggle-label" v-if="checkState">{{checkStateLabelYes}}</span>
    <span class="b-toggle-label" v-else>{{checkStateLabelNo}}</span>

  </button>
</template>

<script>
export default {
    props:['item', 'parent'],
    data(){
        return{
          checkState: this.item.isCheck,
          checkStateLabelYes: window.lang.en.checkLabel.yes,
          checkStateLabelNo: window.lang.en.checkLabel.no,
        }
    },
    methods: {
      toggleFav(item, parent){
        this.checkState = !this.checkState;
        this.item.isCheck = !this.item.isCheck;
        const state = this.checkState;
        const breweryId = this.parent.id;
        const beerId = this.item.id;
        const toggleItem = {
          id: breweryId,
          beer: [{
            id: beerId,
            isCheck: state
          }]
        };
        var stateName="";
        if(state){
          stateName="on";
        }else{
          stateName="off";
        }
        // Check if have local list
        if (localStorage.getItem("savedList") === null) {
          var storedList = new Array() ;
        }else{
          var storedList = JSON.parse(localStorage.getItem('savedList'));
        }

        // Find Brewery index
        const findStoredBrewery = storedList.findIndex(function (item) {
          return item.id == breweryId;
        });


        if(findStoredBrewery >=0){
          const findStoredBeer = storedList[findStoredBrewery].beer.findIndex(function (item) {
            return item.id == beerId;
          });
          if(findStoredBeer>=0){
              storedList[findStoredBrewery].beer[findStoredBeer].isCheck = state;
          }else{
            const newBeer = {
              id: beerId,
              isCheck: state
            };
            storedList[findStoredBrewery].beer.push(newBeer);
          }
        }else{
          storedList.push(toggleItem);
        }

        localStorage.setItem('savedList', JSON.stringify(storedList));
        window.ga('send', 'event', {
          eventCategory: 'Checked',
          eventAction: stateName,
          eventLabel: this.parent.name +": "+ this.item.name
        });
      }
    }
}
</script>
