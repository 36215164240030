<template>
  <div class="c-app">
        <app-header></app-header>
        <router-view :daySelectedSet="daySelectedSet" :searchInputSet="searchInputSet" @setDayChangedHome="dayChanged" setSearchChangedHome="searchChanged"  :information="information" :savedList="savedList" :breweries="breweries" class="c-view"></router-view>
  </div>
</template>

<script>
import Header from './components/shared/header.vue';
import axios from 'axios';
export default {
  data(){
    return{
      information:"",
      breweries:[],
      savedList:[],
      gotData:false,
      isBroken:false,
      daySelectedSet:"",
      searchInputSet:""
    }
  },
  components:{
      appHeader: Header
  },
  methods:{
    dayChanged(val){
      this.daySelectedSet =  val;
    },
     searchChanged(val){
      this.searchInputSet =  val;
    }
  },
  watch:{
    gotData: function(){
      var storedList = JSON.parse(localStorage.getItem('savedList'));
      var hasStoredList = true;
      if(!storedList){
        hasStoredList =  false;
      }
      this.breweries.forEach(function(brewery, index) {
        brewery.key = index;

        if(hasStoredList){
          var storedBrewery = storedList.filter(function(item) {
            return item.id == brewery.id;
          });
        }


        brewery.beer.forEach(function(beer, index){
            beer.key = index;
            var beerFavState = false;
            var beerCheckState = false;
            var beerNote = "";
            if(hasStoredList){
              if(storedBrewery[0]){
                  const storedBeer = storedBrewery[0].beer.filter(function(item) {
                    return item.id == beer.id;
                  });
                  if(storedBeer[0]){
                    beerFavState = storedBeer[0].isFav;
                    beerCheckState = storedBeer[0].isCheck;
                    beerNote = storedBeer[0].note;
                  }
                }
              }
              beer.isFav = beerFavState;
              beer.isCheck = beerCheckState;
              beer.note = beerNote;

        });
      });
    },
  },
  created(){
        axios.get('/data.json')
        .then(res=>{
          const data = res.data.brewer;
          const breweries = [];
          this.information = res.data.information;
          for (let key in data){
            const brewery = data[key];
            brewery.key = key;
            this.breweries.push(brewery);
            this.gotData = true;
          }
        })
        .catch(error=>{
          console.log(error);
        //  this.isBroken=true;
        });
    }
}
</script>

<style lang="scss">

</style>
